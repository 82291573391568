<template>
  <div class="new-video fade-up">
    <el-row :gutter="30" style="position: relative;">
      <el-col :span="16">
        <el-card v-loading="langLoading" class="video-content-card">
          <div class="card-header" slot="header">
            <div class="lang-section">
              <h3>Тилни танланг:</h3>
              <el-radio-group v-model="selectedLang">
                <el-radio-button label="oz">O'zbekcha</el-radio-button>
                <el-radio-button label="uz">Узбекча</el-radio-button>
                <el-radio-button label="ru">Русский</el-radio-button>
              </el-radio-group>
            </div>
          </div>
          <div
            class="title-section"
            :class="{
              'invalid-error': $v.form[`title_${selectedLang}`].$error
            }"
          >
            <h3>Номи</h3>
            <el-input
              size="large"
              placeholder="Номини киритинг"
              v-model="$v.form[`title_${selectedLang}`].$model"
            ></el-input>
          </div>
          <div class="title-section">
            <h3>Видео</h3>
            <file-input
              v-if="!langLoading"
              accept="video/mp4,video/x-m4v,video/*"
              v-model="form[`video_${selectedLang}`]"
            />
          </div>
          <div class="content-section">
            <h3>Контент</h3>
            <quill-editor
              class="editor"
              v-model="form[`content_${selectedLang}`]"
            />
          </div>
        </el-card>
      </el-col>
      <el-col :span="8" style="position: sticky; top: 0">
        <el-card>
          <div
            class="date-section"
            :class="{
              'invalid-error': $v.form.publish_at.$error
            }"
          >
            <h4>Нашр этиш вақти</h4>
            <el-date-picker
              v-model="$v.form.publish_at.$model"
              type="datetime"
              placeholder="Санани танланг"
              class="date-input"
            >
            </el-date-picker>
          </div>
          <div
            class="photo-upload-section"
            :class="{
              'invalid-error': $v.thumbnail.$error
            }"
          >
            <h4>Расм</h4>
            <drop-file-input v-model="$v.thumbnail.$model" />
          </div>
          <div class="flex-end">
            <el-button type="success" icon="el-icon-check" @click="addVideo">
              Қушиш
            </el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import DropFileInput from "@/components/DropFileInput";
import FileInput from "@/components/FileInput";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    DropFileInput,
    FileInput
  },
  data: () => ({
    isCreating: false,
    form: {
      title_uz: "",
      title_ru: "",
      title_oz: "",
      video_uz: null,
      video_ru: null,
      video_oz: null,
      content_uz: "",
      content_ru: "",
      content_oz: "",
      publish_at: "",
      status: "draft"
    },
    selectedLang: "oz",
    thumbnail: null,
    langLoading: false
  }),
  validations: {
    form: {
      title_uz: { required },
      title_ru: { required },
      title_oz: { required },
      publish_at: { required }
    },
    thumbnail: { required }
  },
  watch: {
    selectedLang() {
      this.langLoading = true;
      setTimeout(() => {
        this.langLoading = false;
      }, 500);
    }
  },
  methods: {
    addVideo() {
      if (this.isCreating) return;

      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$notify.error({
          title: "Диққат!",
          message: "Формани тўлдиринг!"
        });

        return false;
      } else {
        this.isCreating = true;
        const loading = this.$loading({
          lock: true
        });
        const data = { ...this.form };
        delete data.video_uz;
        delete data.video_ru;
        delete data.video_oz;
        const formData = new FormData();
        if (+new Date(this.form.publish_at) <= +new Date()) {
          data.status = "published";
        }
        formData.append("data", JSON.stringify(data));
        formData.append("files.thumbnail", this.thumbnail);
        if (this.form.video_uz) {
          formData.append("files.video_uz", this.form.video_uz);
        }
        if (this.form.video_oz) {
          formData.append("files.video_oz", this.form.video_oz);
        }
        if (this.form.video_ru) {
          formData.append("files.video_ru", this.form.video_ru);
        }
        this.$store
          .dispatch("addVideo", formData)
          .then(() => {
            this.$message({
              message: "Видео қушилди!",
              type: "success"
            });
            this.$router.push("/videos");
          })
          .finally(() => {
            this.isCreating = false;
            loading.close();
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title-section,
.content-section,
.desc-section,
.hashtag-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  h3 {
    margin-bottom: 10px;
  }
}

.video-content-card {
  position: relative;
  .el-card__header {
    position: sticky;
    top: 0;
  }
}

.lang-section h3 {
  margin-bottom: 10px;
}

.new-video {
  padding: 20px;
}

// .editor {
//   height: 400px;
//   display: flex;
//   flex-direction: column;
// }

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.category-section,
.photo-upload-section,
.date-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  h4 {
    margin-bottom: 10px;
  }
}

.desc-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date-input {
  width: 100%;
}
</style>
